export const stringToBase64 = (str) => {
    if (typeof str === "object") { str = JSON.stringify(str) }
    return window.btoa(unescape(encodeURIComponent(str)));
}

export const base64ToString = (str) => {
    return decodeURIComponent(escape(window.atob(str)));
}

/**
 * ============================================================================
 * PRODUCTION ENVIRONMENT CONSTANS
 * ============================================================================
 */

export const ERROR_REPORTING_URL = "https://axis.uninunez.edu.co/apildap/api/log/errorwrite";


// API KEY FOR RECAPTCHA 
export const RECAPTCHA_API_KEY_V3 = `6LdKUNUZAAAAAPVx2_bT1CtIm3t4Kj9437hReFoa`;
export const RECAPTCHA_API_KEY = `6LeB0W4UAAAAACXqVNcH-HWzYjcgCw2sY7iMtu0R`;

// BASE URL DOMAIN AXIS 
export const AXIS_BASE_URL = `https://axis.uninunez.edu.co/`;

//APIs URLs
export const API_NSC = `${AXIS_BASE_URL}apinsc/api/`;


/**
 * ============================================================================
 * DEVELOPMENT ENVIRONMENT CONSTANS
 * ============================================================================
 */



