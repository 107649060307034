export const SET_STEP = "SET_STEP";
export const SET_FASE = "SET_FASE";
export const SET_INSCRIPTIONS = "SET_INSCRIPTIONS";
export const SET_STEP_LIST = "SET_STEP_LIST";
export const FINAL_PROCESS = "FINAL_PROCESS";
export const SET_CURRENT_INSCRIPTION_STATUS = "SET_CURRENT_INSCRIPTION_STATUS";
export const CLEAR_STATUS = "CLEAR_STATUS";

export const setInscriptions = (payload) => ({ payload, type: SET_INSCRIPTIONS });
export const setStepList = (payload) => ({ payload, type: SET_STEP_LIST });
export const setCurrentInscriptionStatus = (payload) => ({ payload, type: SET_CURRENT_INSCRIPTION_STATUS });
export const clearStatus = (payload) => ({ payload, type: CLEAR_STATUS });
export const finalProcess = () => ({ type: FINAL_PROCESS });
export const setStep = (payload) => payload === 1000 ? finalProcess() : ({ payload, type: SET_STEP });
export const setFase = (payload) => payload === 1000 ? finalProcess() : ({ payload, type: SET_FASE });