import { stringToBase64, base64ToString } from '../../Services/constantsService';
import { SET_STEP, SET_INSCRIPTIONS, SET_STEP_LIST, SET_FASE, SET_CURRENT_INSCRIPTION_STATUS, CLEAR_STATUS, FINAL_PROCESS } from './../action/inscriptionAction';

let inscriptions = localStorage.getItem("assistant/inscriptions");

try {
    if (!!(inscriptions)) { inscriptions = JSON.parse(base64ToString(inscriptions)) } else { inscriptions = [] }
} catch (error) {
    inscriptions = [];
}

const defaultState = {
    currentStep: 0,
    currentFase: 0,
    inscriptions,
    stepList: null,
    totalStepList: 0,
    inscriptionProgress: 0,
    currentInscriptionStatus: null
};

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_STEP:
            let d = null
            if (action.payload < state.stepList[state.currentFase].length) {
                d = {
                    ...state,
                    currentStep: action.payload
                }
            } else {
                d = {
                    ...state,
                    currentStep: 0,
                    currentFase: state.currentFase + 1,
                }
            };
            let prog = 0;
            for (let i = d.currentFase - 1; i >= 0; i--) { prog += d.stepList[i].length }
            d.inscriptionProgress = Math.round(((prog + d.currentStep) * 100) / d.totalStepList);
            return d
        case SET_FASE:
            let progr = 0;
            for (let i = action.payload - 1; i >= 0; i--) { progr += state.stepList[i].length }
            return {
                ...state,
                currentFase: action.payload,
                inscriptionProgress: Math.round(((progr + state.currentStep) * 100) / state.totalStepList)
            };
        case SET_INSCRIPTIONS:
            let insc = action.payload.sort((a, b) => (new Date(b.DATINS).getTime() > new Date(a.DATINS).getTime()) ? 1 : -1)
            localStorage.setItem("assistant/inscriptions", stringToBase64(JSON.stringify(insc)))
            return {
                ...state,
                inscriptions: insc
            };
        case SET_CURRENT_INSCRIPTION_STATUS:
            return {
                ...state,
                currentInscriptionStatus: action.payload,
                // inscriptionProgress: 0
            };
        case FINAL_PROCESS:
            return {
                ...state,
                inscriptionProgress: 100,
                currentFase: 1000,
                currentStep: 1000
            };
        case SET_STEP_LIST:
            // localStorage.setItem("assistant/steplist", stringToBase64(JSON.stringify(action.payload)));
            let fases = {}
            action.payload.sort((a, b) => (a.orde_paso > b.orde_paso) ? 1 : -1).forEach(e => fases.hasOwnProperty(e.fase) ? fases[e.fase].push(e) : fases[e.fase] = [e]);
            return {
                ...state,
                stepList: Object.values(fases),
                totalStepList: action.payload.length
            };
        case CLEAR_STATUS:
            return {
                ...state,
                currentStep: 0,
                currentFase: 0,
                currentInscriptionStatus: null,
                inscriptionProgress: 0
            };

        default:
            return { ...state };
    }
}

export default reducer;