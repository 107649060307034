import React, { lazy, Suspense } from 'react';
import lazyLoaderComponents from './../Services/lazyLoaderComponents';
import {
    HashRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import Placeholder from './../Components/Loader/Placeholder';
import Footer from "./../Components/Footer";
import { connect } from 'react-redux';
import { UncontrolledAlert } from 'reactstrap';

const ConvocatoryAsistantScreen = lazy(() => lazyLoaderComponents(() => import('./ConvocatoryAsistantScreen')))
const HomeScreen = lazy(() => lazyLoaderComponents(() => import('./Home')))
const LoginScreen = lazy(() => lazyLoaderComponents(() => import('./Login')))
const InscriptionsScreen = lazy(() => lazyLoaderComponents(() => import('./Inscriptions')))

const ScreensRoot = (props) => {
    return (
        <>
            <main>
                <div className="position-absolute w-100 d-flex justify-content-center" style={{ zIndex: "100" }}>
                    {props.unauthorized === true && <UncontrolledAlert color="danger" className="rounded mt-4 shadow"><b>Su sesión ha expirado</b></UncontrolledAlert>}
                </div>
                <Suspense fallback={<Placeholder height="100%" />}>
                    {!!(props.userInfo) && !(props.unauthorized) ?
                        <Router>
                            <Switch>
                                <Route exact path="/inicio/:id" component={HomeScreen} />
                                <Route path="/inscripciones" component={InscriptionsScreen} />
                                <Route path="*">
                                    {props.inscriptions.length === 0 ? <Redirect to={"/inscripciones/nueva"} />
                                        :
                                        <Redirect to={"/inscripciones"} />
                                    }
                                </Route>
                            </Switch>
                        </Router>
                        :
                        <Router>
                            <Switch>
                                <Route exact path="/asistente-convocatoria" component={ConvocatoryAsistantScreen} />
                                <Route exact path="/ingresar" component={LoginScreen} />
                                <Route path="*">
                                    <Redirect to="/ingresar" />
                                </Route>
                            </Switch>
                        </Router>
                    }
                </Suspense>

            </main>
            {(window.location.hash !== "#/asistente-convocatoria") && <Footer />}
        </>
    );
};

const mapStateToProps = state => ({
    userInfo: state.user.userInfo,
    unauthorized: state.user.unauthorized,
    inscriptions: state.inscription.inscriptions
})

export default connect(mapStateToProps)(ScreensRoot);