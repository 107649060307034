import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

Placeholder.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
};

function Placeholder(props) {
    const height = props.height || "100%";
    const width = props.width || "100%";
    const _style = !!(props.style) ? { width: width, height: height, ...props.style } : { width: width, height: height }
    return (
        // <div className="spinner-border text-warning" role="status">
        //     <span className="sr-only">Loading...</span>
        // </div>
        <div className={`NG-placeholder-loader ${props.className}`} style={_style}>
            <div className="h-100 d-flex justify-content-center align-items-center">
                <div className="spinner-border text-warning" role="status"></div>
            </div>
            {/* <div className="placeholder-item"></div> */}
        </div>
    );
}

export default Placeholder;