import React from 'react';

const index = () => {
    return (
        <footer className="position-relative mt-5">
            <div className="p-3 card-glass text-center">
                <p className="mb-0">
                    <small>
                        Corporación Universitaria Rafael Núñez | Institución Universitaria | Vigilada Mineducación
                    </small>
                </p>
            </div>
        </footer>
    );
};

export default index;