import { SET_USERINFO, SET_UNAUTHORIZED, LOGOUT } from './../action/userAction';

const defaultState = {
    userInfo: JSON.parse(localStorage.getItem("assistant/user")) || null,
    unauthorized: false
};

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_USERINFO:
            localStorage.setItem("assistant/user", JSON.stringify(action.payload))
            return {
                ...state,
                userInfo: action.payload
            };
        case LOGOUT:
            localStorage.removeItem("assistant/user")
            localStorage.removeItem("assistant/token")
            localStorage.removeItem("assistant/inscriptions")
            window.location.reload();
            return {
                ...state,
                userInfo: null
            };
        case SET_UNAUTHORIZED:
            localStorage.removeItem("assistant/user")
            localStorage.removeItem("assistant/token")
            localStorage.removeItem("assistant/inscriptions")
            return {
                ...state,
                userInfo: null,
                unauthorized: !!(action.payload)
            }
        default:
            return { ...state };
    }
}

export default reducer;