import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './Store';
import './bootstrap.min.css';
import './index.css';
import ScreensRoot from './Screens';
import ErrorHandler from './Components/ErrorHandler';
import { AXIOS_REQUEST } from './Services/axiosService';

AXIOS_REQUEST(null, 'get', null, false, "https://axis.uninunez.edu.co/apiaxis/api/mantenimiento/asistenteinscripciones")
  .then(resp => {
    if (!!(resp) && typeof resp === 'string') {
      window.location = resp;
    }
  }).catch(() => { })

ReactDOM.render(
  <React.StrictMode>
    <ErrorHandler>
      <Provider store={store}>
        <ScreensRoot />
      </Provider>
    </ErrorHandler>
  </React.StrictMode>,
  document.getElementById('root')
);
