import { AXIOS_REQUEST } from "../../Services/axiosService";

export const SET_USERINFO = "SET_USERINFO";
export const SET_UNAUTHORIZED = "SET_UNAUTHORIZED";
export const LOGOUT = "LOGOUT";

export const setUserInfo = payload => ({ payload, type: SET_USERINFO });
export const setUnauthorized = (payload) => ({ payload, type: SET_UNAUTHORIZED });
export const logout = () => ({ type: LOGOUT });

